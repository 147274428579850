import React from 'react'
import { Link } from 'gatsby'

import SEO from '../components/SEO'
import { Container } from '../styles/PageLayout'
import { Headline, ParagraphM } from '../styles/Typography'

const ShopPage = () => (
  <>
    <SEO title="Shop" />
    <Container column mobileColumn minHeight="75vh" gap="1.5em">
      <Headline>Coming soon!</Headline>
      <Link to="/gallery">
        <ParagraphM>← check out the gallery in the meantime</ParagraphM>
      </Link>
    </Container>
  </>
)

export default ShopPage
